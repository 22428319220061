// 仪表盘
import { Request } from '@/http/request'

// 用户号码统计数据展示
export function countBillTotal (parameter: any) {
  return Request.axiosInstance.post('/open/user-number-count/count-bill-total', parameter)
}
// 用户号码统计数据展示
export function checkDetail (parameter: any) {
  return Request.axiosInstance.post('/open/user-number-count/account-fold-line', parameter)
}
// 用户号码统计数据展示
export function checkDetailAll (parameter: any) {
  return Request.axiosInstance.post('/open/user-number-count/account-total-fold-line', parameter)
}
export default { countBillTotal ,checkDetail,checkDetailAll}