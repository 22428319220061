<template>
  <div class="root">
    <!-- 1.头部 -->
    <div class="title">
      <div class="name">
        <span @click="$router.back(-1)" :style="{cursor:'pointer',fontSize:'15px'}">
          <i class="el-icon-arrow-left">返回</i>
        </span>
        <span :style="{marginLeft:'40px',fontSize:'18px',fontWeight:'550'}">{{amountName}}</span>
      </div>
      <div class="fr">
        <div>
          <el-date-picker value-format="YYYY-MM-DD" v-model="dateDefaultValue" type="daterange" clearable unlink-panels
            range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" @change="dateChange" />
        </div>
        <div>
          <el-select v-model="selectDefaultValue" @change="choice">
            <el-option v-for="(item, index) in list" :key="index" :label="item.label" :value="item.label"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- 2.图表 -->
    <div id="myChart1"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { checkDetail } from '@/api/dashboard/userNumberCount'
const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj)
export default {
  name: 'Trend',
  components: {},
  data() {
    return {
      dateDefaultValue: [], //默认日期
      selectDefaultValue: '呼叫次数',
      list: [
        {
          label: '呼叫次数',
          value: 1,
        },
        {
          label: '接听次数',
          value: 2,
        },
        {
          label: '分钟数(分钟)',
          value: 3,
        },
        {
          label: '通话费(元)',
          value: 4,
        },
        {
          label: '取整分钟',
          value: 5,
        },
        {
          label: '取整利润',
          value: 6,
        },
        {
          label: '成本通话费(元)',
          value: 7,
        },
        {
          label: '利润(元)',
          value: 8,
        },
      ], //下拉框数组
      // 折线图数据
      colorList: ['#0170ED', '#67C23A', '#E6A23C', '#E6A23C', '#01C3ED', '#FF845B '], //折线颜色
      nameList: [
        '呼叫次数',
        '接听次数',
        '分钟数(分钟)',
        '通话费(元)',
        '取整分钟',
        '取整利润',
        '成本通话费(元)',
        '利润(元)',
      ],
      series: [],
      bottomData: [], //折线图下标
      chartInstance: null,
      updateOption: {},
      amountName: '', //公司名
      amountId: '', //公司ID
      originalMinutesList: [], //取整分钟
      roundPriceList: [], //取整利润
      billNumList: [], //呼叫次数
      callNumList: [], //接听次数
      callTimeList: [], //分钟数(分钟)
      costPriceList: [], //成本通话费(元)
      callChargeList: [], //通话费(元)
      callProfitList: [], //利润(元)
      search_form: {
        dateBegin: '',
        dateEnd: '',
      },
      newDataList: [],
    }
  },
  mounted() {
    this.amountName = this.$route.query.amountName
    this.amountId = this.$route.query.amountId
    this.search_form.dateBegin = this.$route.query.dateBegin
    this.search_form.dateEnd = this.$route.query.dateEnd
    //设置默认日期
    this.dateDefaultValue = [
      this.search_form.dateBegin,
      this.search_form.dateEnd,
    ]
    this.getCountBillTotal()
  },
  methods: {
    //修改日期
    dateChange(dateValue) {
      //获取日期参数重新请求数据
      this.search_form.dateBegin = dateValue[0]
      this.search_form.dateEnd = dateValue[1]
      this.getCountBillTotal()
      this.selectDefaultValue = this.list[0].label
    },
    //下拉改变图例
    choice(item) {
      const selectOption = {}
      for (let i in this.nameList) {
        selectOption[this.nameList[i]] = false
        selectOption[item] = true
      }
      //修改图例改变图表
      this.updateOption.legend.selected = selectOption
      unwarp(this.chartInstance).setOption(this.updateOption)
    },
    // 获取折线图数据
    getCountBillTotal() {
      checkDetail({
        ...this.search_form,
        amountId: this.amountId,
      }).then((res) => {
        //获取数据前先清空日期数组和提示框数组
        this.bottomData = []
        this.series = []
        if (res.data.length === 0) {
          return this.$message.warning('此时间段暂无数据')
        }
        res.data.forEach((item) => {
          this.bottomData.unshift(item.date)
          //呼叫次数
          if (item.billNum == null) {
            this.billNumList.unshift(0)
          } else {
            this.billNumList.unshift(item.billNum)
          }
          //接听次数
          if (item.callNum == null) {
            this.callNumList.unshift(0)
          } else {
            this.callNumList.unshift(item.callNum)
          }
          // 分钟数(分钟)
          if (item.callTime == null) {
            this.callTimeList.unshift(0)
          } else {
            this.callTimeList.unshift(item.callTime)
          }
          // 成本通话费(元)
          if (item.costPrice == null) {
            this.costPriceList.unshift(0)
          } else {
            this.costPriceList.unshift(item.costPrice)
          }
          // 通话费(元)
          if (item.callCharge == null) {
            this.callChargeList.unshift(0)
          } else {
            this.callChargeList.unshift(item.callCharge)
          }
          // 利润(元)
          if (item.callProfit == null) {
            this.callProfitList.unshift(0)
          } else {
            this.callProfitList.unshift(item.callProfit)
          }
          // 取整分钟
          if (item.originalMinutes == null) {
            this.originalMinutesList.unshift(0)
          } else {
            this.originalMinutesList.unshift(
              parseInt(item.callTime) - parseInt(item.originalMinutes)
            )
          }
          // 取整利润
          if (item.roundPrice == null) {
            this.roundPriceList.unshift(0)
          } else {
            this.roundPriceList.unshift(item.roundPrice)
          }
        })
        this.newDataList = [
          this.billNumList,
          this.callNumList,
          this.callTimeList,
          this.callChargeList,
          this.originalMinutesList,
          this.roundPriceList,
          this.costPriceList,
          this.callProfitList,
        ]
        //半透明颜色值
        const colorArr1 = [
          'rgba(11,168,44,0.5)',
          'rgba(44,110,255,0.5)',
          'rgba(22,242,217,0.5)',
          'rgba(254,33,30,0.5)',
          'rgba(250,205,0,0.5)',
          'rgba(250,205,0,0.5)',
          'rgba(44,110,255,0.5)',
          'rgba(22,242,217,0.5)',
        ]
        //全透明颜色值
        const colorArr2 = [
          'rgba(11,168,44,0)',
          'rgba(44,110,255,0)',
          'rgba(22,242,217,0)',
          'rgba(254,33,30,0)',
          'rgba(250,205,0,0)',
          'rgba(250,205,0,0)',
          'rgba(44,110,255,0)',
          'rgba(22,242,217,0)',
        ]
        this.newDataList.forEach((datas, d) => {
          this.series.push({
            name: this.nameList[d],
            type: 'line',
            data: this.newDataList[d],
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: colorArr1[d] },
                { offset: 1, color: colorArr2[d] },
              ]),
            },
          })
        })
        this.chartInstance = echarts.init(document.getElementById('myChart1'))
        this.updateOption = {
          legend: {
            data: this.nameList,
            left: 150,
            selected: {
              '呼叫次数': true,
              '接听次数': false,
              '分钟数(分钟)': false,
              '通话费(元)': false,
              '取整分钟': false,
              '取整利润': false,
              '成本通话费(元)': false,
              '利润(元)': false,
            },
            selectedMode: false,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.bottomData,
          },
          axisPointer: {
            type: 'cross',
          },
          yAxis: {
            type: 'value',
            minInterval: 1,
            axisPointer: {
              type: 'line',
              snap: true,
            },
          },
          dataZoom: [
            {
              type: 'inside',
            },
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
            },
          },
          series: this.series,
        }
        unwarp(this.chartInstance).setOption(this.updateOption)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .name {
      width: 500px;
    }
    .fr {
      height: 100%;
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
  }
  #myChart1 {
    height: 90%;
    width: 100%;
  }
}
</style>
